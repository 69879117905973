export default {
  abort: 'Abort',
  alumni: {
    invalidCode: 'The code is not valid.',
  },
  error: 'An error occurred.',
  goToPage: 'Go to page {{value}}',
  invalidPhoneNumber: 'The phone number is not valid.',
  upload: {
    maxFilesReached: 'You can only upload {{value}} files.',
    label: 'Drag & drop your files or',
    labelBrowse: 'Browse',
    requiredMultiple: 'Please select one or more files.',
    requiredSingle: 'Please select a file.',
  },
}
