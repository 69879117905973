import '~/styles/main.css'
import 'vidstack/player'
import 'vidstack/player/layouts'
import 'vidstack/player/ui'
import App from '~/scripts/App'
import setScrollbarWidth from '~/scripts/tailwindcss/plugins/spacing-container/set-scrollbar-width'
import { flat } from 'radash'

// Create a better Eulerian Analytics push function
window.eaPush = (event) => {
  if (window.EA_push) {
    window.EA_push(...flat(Object.entries(event)))
  }
}

// Store UTM source in session storage
const urlParameters = new URLSearchParams(window.location.search)
const utmSource = urlParameters.get('utm_source')
if (utmSource) {
  window.sessionStorage.setItem('utm_source', utmSource)
}

document.addEventListener('DOMContentLoaded', async () => {
  await document.fonts.ready
  App()
})

const scrollbarWidthEvents = ['load', 'resize']
for (const event of scrollbarWidthEvents) {
  window.addEventListener(event, () => {
    setScrollbarWidth()
  })
}
setScrollbarWidth()
