import { autoUpdate, computePosition, flip, shift } from '@floating-ui/dom'
import { defineComponent } from '~/scripts/utils/alpine'
import { filterElement } from '~/scripts/utils/dom'
import { normalizeString } from '~/scripts/utils/string'

export interface ComboboxOptions {
  value?: unknown
}

/**
 * Combobox component
 * The search input will filter the options based on its text content and the `data-filter` attribute.
 * @event combobox:change - When an option is selected
 * @event combobox:input - When the value changes
 * @example With a default value:
    <div
      x-data="Combobox({ value: { value: 'ch', label: 'Suisse' } })"
      x-bind="root"
      @combobox:input="console.log('Select value', $event.detail)"
    >
      <button x-bind="button">
        <span x-text="value?.label || 'Select an option'"></span>
        <iconify-icon icon="solar:alt-arrow-down-outline" class="align-middle"></iconify-icon>
      </button>
      <div x-bind="menu">
        <input type="search" x-model="search" class="min-w-0" />
        <div x-ref="options" class="flex flex-col">
          <button type="button" data-country="FR" @click="select({ value: 'fr', label: 'France' })">France</button>
          <button type="button" @click="select({ value: 'ch', label: 'Suisse' })">Suisse</button>
          <button type="button" @click="select({ value: 'it', label: 'Italie' })">Italie</button>
        </div>
      </div>
    </div>
 */
export default defineComponent((options?: ComboboxOptions) => ({
  value: (options?.value ?? undefined) as unknown,
  expanded: false,
  id: undefined as string | undefined,
  cleanup: undefined as (() => void) | undefined,
  search: '',
  async init() {
    this.id = this.$id('combobox')

    this.$watch('search', this.filterOptions.bind(this))

    this.$watch('expanded', async (newValue) => {
      if (newValue) {
        await this.$nextTick()
        const { button, menu } = this.$refs

        const input = menu.querySelector('input[type="search"]')
        if (input instanceof HTMLInputElement) {
          input.focus()
        }

        this.cleanup = autoUpdate(button, menu, this.updatePosition.bind(this))
      } else {
        if (this.cleanup) {
          this.cleanup()
          this.cleanup = undefined
        }
      }
    })

    this.$watch('value', (value) => {
      this.$dispatch('combobox:input', { value })
      this.expanded = false
    })
  },
  select(value: unknown) {
    this.$dispatch('combobox:change', { value, target: this.$el })
    this.value = value
  },
  filterOptions() {
    const options = this.$refs.options.children
    const search = normalizeString(this.search)

    for (const option of options) {
      if (option instanceof HTMLElement) {
        option.style.display = filterElement(option, search) ? '' : 'none'
      }
    }
  },
  async updatePosition() {
    const { button, menu } = this.$refs
    const position = await computePosition(button, menu, {
      placement: 'bottom-start',
      middleware: [flip({ padding: 8 }), shift({ padding: 8 })],
    })

    Object.assign(this.$refs.menu.style, {
      left: `${position.x}px`,
      top: `${position.y}px`,
    })
  },
  root: {
    class: 'relative',
    '@keydown.escape'() {
      this.expanded = false
    },
    '@combobox:close'(event: Event) {
      event.stopPropagation()
      this.expanded = false
    },
  },
  button: {
    'x-ref': 'button',
    ':id'() {
      return this.id
    },
    'aria-haspopup': 'true',
    ':aria-expanded'() {
      return this.expanded
    },
    type: 'button',
    role: 'combobox',
    '@click'() {
      this.expanded = !this.expanded
    },
  },
  menu: {
    'x-ref': 'menu',
    'aria-orientation': 'vertical',
    ':aria-labelledby'() {
      return this.id
    },
    role: 'menu',
    tabindex: '-1',
    class:
      'absolute z-[100] left-0 top-0 w-max bg-white border border-tundora-200 rounded shadow-lg',
    'x-show'() {
      return this.expanded
    },
    'x-transition:enter'() {
      return 'transition ease-out duration-100'
    },
    'x-transition:enter-start'() {
      return 'opacity-0 scale-95'
    },
    'x-transition:leave'() {
      return 'transition ease-in duration-75'
    },
    'x-transition:leave-end'() {
      return 'opacity-0 scale-95'
    },
    '@click.outside'() {
      this.expanded = false
    },
  },
}))
