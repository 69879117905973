import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { defineDirective } from '~/scripts/utils/alpine'

/**
 * x-reveal directive
 * @example Reveal an element when it's in the viewport
 * <div x-reveal></div>
 */
export default defineDirective((element) => {
  ScrollTrigger.create({
    trigger: element,
    start: 'top 80%',
    once: true,
    onEnter: () => {
      element.classList.add('revealed')
    },
  })
})
