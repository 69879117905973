interface FormToObjectOptions {
  /**
   * Whether to include empty values in the result
   * @default false
   */
  includeEmpty?: boolean
}

function isEmpty(value: FormDataEntryValue) {
  return value === '' || (typeof value !== 'string' && value.name === '')
}

/**
 * Convert a form element to an object
 * @param form Form element or FormData
 * @param options Options
 */
export function formToObject(
  form: HTMLFormElement | FormData,
  options?: FormToObjectOptions,
) {
  const formData = form instanceof FormData ? form : new FormData(form)
  const data = {} as Record<string, string | string[]>
  for (const [key, value] of formData.entries()) {
    if (value instanceof File) {
      // Skip file inputs
      continue
    }
    if (!options?.includeEmpty && isEmpty(value)) {
      // Skip empty values
      continue
    }

    if (key.endsWith('[]')) {
      // Array values
      const k = key.slice(0, -2)
      data[k] = formData.getAll(key) as string[]
    } else {
      // Single value
      data[key] = value as string
    }
  }
  return data
}

/**
 * Reset the options of a select element (except the one with an empty value)
 * @param target Select element
 */
export function resetSelectOptions(target: HTMLSelectElement) {
  target.value = ''
  for (let index = target.children.length - 1; index >= 0; index--) {
    if (target.item(index)?.value) {
      target.remove(index)
    }
  }
  return target
}

/**
 * Get the label of a given value in a select element
 * @param form Form element containing the select element
 * @param name Name of the select element
 * @param value Value to search for
 * @returns Label of the option
 */
export function getSelectOptionLabel(
  form: HTMLFormElement,
  name: string,
  value: string,
): string | undefined {
  const option = form.querySelector<HTMLOptionElement>(
    `select[name="${name}"] option[value="${value}"]`,
  )
  return option?.label
}
