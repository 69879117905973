import { gsap } from 'gsap'
import { defineComponent } from '~/scripts/utils/alpine'
import screens from '#tailwindcss/screens.json'

export default defineComponent(() => ({
  init() {
    const { video } = this.$refs
    if (!(video instanceof HTMLVideoElement)) return

    const { srcSquare, srcLandscape } = video.dataset
    if (!srcSquare && !srcLandscape) return

    const matchMedia = gsap.matchMedia()

    matchMedia.add(
      {
        square: `(max-width: 400px), (min-width: ${screens.lg})`,
        landscape: `(min-width: 401px) and (max-width: ${Number.parseInt(screens.lg) - 1}px)`,
      },
      (context) => {
        // Update video source
        if (context.conditions?.square) {
          video.src = srcSquare || srcLandscape || ''
        }
        if (context.conditions?.landscape) {
          video.src = srcLandscape || srcSquare || ''
        }

        // Update aspect ratio
        if (video.src === srcLandscape) {
          video.classList.remove('aspect-square')
          video.classList.add('aspect-video')
        } else {
          video.classList.remove('aspect-video')
          video.classList.add('aspect-square')
        }
      },
    )
  },
}))
