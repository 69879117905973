import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { defineComponent } from '~/scripts/utils/alpine'

export default defineComponent(() => ({
  init() {
    const { text } = this.$refs

    if (text) {
      ScrollTrigger.create({
        trigger: text,
        start: 'top center',
        once: true,
        onEnter: () => {
          this.animateText(text)
        },
      })
    }
  },
  animateText(text: HTMLElement) {
    for (const strong of text.querySelectorAll('strong')) {
      const index = [...strong.parentElement!.children].indexOf(strong)
      strong.classList.add('transition-colors', 'duration-1000', 'ease-natural')
      gsap.delayedCall(index * 0.1, () => {
        strong.classList.add('text-amaranth-600')
      })
    }
  },
}))
