import { shuffle } from 'radash'
import { normalizeString } from '~/scripts/utils/string'

/**
 * Check if an element contains the search string in its text content or in its dataset (`data-*` attributes).
 * @param element - The element to filter
 * @param search - The search string
 * @returns The result of the search
 */
export function filterElement(element: HTMLElement, search: string): boolean {
  if (!search) return true
  const values = [element.textContent, ...Object.values(element.dataset)].map(
    (value) => normalizeString(value || ''),
  )
  if (values.some((value) => value.includes(search))) {
    return true
  }
  return false
}

/**
 * Shuffle the children of an element.
 * @param element - The element
 * @returns The shuffled children as a document fragment
 */
export function shuffleChildren(element: HTMLElement): DocumentFragment {
  const children = shuffle([...element.children])

  const fragment = document.createDocumentFragment()
  for (const child of children) fragment.append(child)

  return fragment
}
