import { ofetch } from 'ofetch'

export interface SubmitEudonetFormResponse {
  success: true
  data: {
    id: string
  }
}

export interface SubmitEudonetFormError {
  success: false
  data: Record<string, Record<string, string>>
}

/**
 * Send form to eudonet
 * @param body - The form data
 * @returns The response from the server
 */
export function submitEudonetForm(body: Record<string, string | string[]>) {
  return ofetch<SubmitEudonetFormResponse>('/wp-json/crea-eudonet/submit', {
    method: 'POST',
    body,
  })
}
