import gsap from 'gsap'
import { colord } from 'colord'
import { defineDirective } from '~/scripts/utils/alpine'
import colors from '#tailwindcss/colors.json'

function propertyOf<T extends Record<string, unknown>>(
  key: string | number | symbol,
  object: T,
): key is keyof T {
  return key in object
}

function parseColor(value: string) {
  return (
    /(?<name>[a-z-]+)(\.?(?<shade>\d+))?(\/(?<alpha>\d+))?/gm.exec(value)
      ?.groups || {}
  )
}

function parseExpression(expression: string): string {
  if (expression.startsWith('#')) return expression
  if (expression) {
    const { name, shade, alpha } = parseColor(expression)

    const formatColor = (value: string) =>
      colord(value)
        .alpha(Number.parseInt(alpha) / 100)
        .toRgbString()

    if (propertyOf(name, colors)) {
      const color = colors[name]
      // The color is just a string
      if (typeof color === 'string') {
        return formatColor(color)
      }
      // The color is an object of shades
      else if (propertyOf(shade, color)) {
        return formatColor(color[shade])
      }
    }

    // This could be a valid css color
    if (colord(expression).isValid()) return formatColor(expression)
  }

  return 'rgba(255, 255, 255, 0.15)'
}

/**
 * x-glow-effect directive
 * @example Will add a glow effect on the element
 * <button x-glow-effect class="button">Click me</button>
 *
 * @example With a custom radius (300px) and color (red)
 * <button x-glow-effect.300px="#ff0000" class="button">Click me</button>
 *
 * @example A tailwindcss color (yep, even your custom ones)
 * <button x-glow-effect="lime.500/20" class="button">Click me</button>
 */
export default defineDirective(
  (element, { expression, modifiers }, { cleanup }) => {
    // If is touch device
    if (window.matchMedia('(hover: none)').matches) return
    const units = ['px', 'em', 'rem', 'vw', 'vh', 'vmin', 'vmax', '%']
    let radius = '130px'
    if (modifiers[0] && units.some((unit) => modifiers[0].endsWith(unit))) {
      radius = modifiers[0]
    }

    const color = parseExpression(expression)

    const overlay = document.createElement('span')
    overlay.classList.add(
      'absolute',
      'inset-0',
      'pointer-events-none',
      'transition-opacity',
      'duration-700',
      'ease-natural',
    )
    Object.assign(overlay.style, {
      borderRadius: 'inherit',
      background: `radial-gradient(
      ${radius} circle at calc(var(--gradient-x, 0) * 1%) calc(var(--gradient-y, 0) * 1%),
      ${color},
      transparent 40%
    )`,
      opacity: 0,
    })

    const xTo = gsap.quickTo(overlay, '--gradient-x', {
      duration: 0.6,
      ease: 'power3',
    })
    const yTo = gsap.quickTo(overlay, '--gradient-y', {
      duration: 0.6,
      ease: 'power3',
    })

    function update(event: MouseEvent, animate?: boolean) {
      const rect = element.getBoundingClientRect()
      const offsetX = event.pageX - window.scrollX - rect.left
      const offsetY = event.pageY - window.scrollY - rect.top
      const x = (offsetX * 100) / rect.width
      const y = (offsetY * 100) / rect.height

      xTo(x, animate ? undefined : x)
      yTo(y, animate ? undefined : y)
    }

    function onMouseEnter(event: MouseEvent) {
      update(event)
      overlay.style.opacity = '1'
    }

    function onMouseLeave() {
      overlay.style.opacity = '0'
    }

    function onMouseMove(event: MouseEvent) {
      update(event, true)
    }

    element.addEventListener('mouseenter', onMouseEnter)
    element.addEventListener('mouseleave', onMouseLeave)
    element.addEventListener('mousemove', onMouseMove)

    element.append(overlay)

    cleanup(() => {
      element.removeEventListener('mouseenter', onMouseEnter)
      element.removeEventListener('mouseleave', onMouseLeave)
      element.removeEventListener('mousemove', onMouseMove)
    })
  },
)
