import { Loader } from '@googlemaps/js-api-loader'
import { defineComponent } from '~/scripts/utils/alpine'
import colors from '#tailwindcss/colors.json'

const loader = new Loader({
  apiKey: import.meta.env.VITE_GMAPS_API_KEY,
  version: 'weekly',
})

export default defineComponent(() => ({
  instance: undefined as google.maps.Map | undefined,
  async init() {
    const [{ Point, LatLngBounds }, { Map }, { Marker }] = await Promise.all([
      loader.importLibrary('core'),
      loader.importLibrary('maps'),
      loader.importLibrary('marker'),
    ])

    this.instance = new Map(this.$refs.map, {
      center: { lat: 46.359, lng: 6.536 },
      zoom: 10,
      mapId: import.meta.env.VITE_GMAPS_MAP_ID,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      controlSize: 22,
    })

    const bounds = new LatLngBounds()

    const icon: google.maps.Symbol = {
      /*
        Icon: https://icones.js.org/collection/ph?s=marker&icon=ph:map-pin-fill
        1: copy svg (snipper svg)
        2: paste in figma
        3: look for the anchor point at bottom center (you can drop a rectangle and read its xy position)
        4: update path and anchor here
        */
      path: 'M128 16a88.1 88.1 0 0 0-88 88c0 75.3 80 132.17 83.41 134.55a8 8 0 0 0 9.18 0C136 236.17 216 179.3 216 104a88.1 88.1 0 0 0-88-88m0 56a32 32 0 1 1-32 32a32 32 0 0 1 32-32',
      fillColor: colors.amaranth[600],
      fillOpacity: 1,
      strokeWeight: 2,
      strokeColor: colors.amaranth[800],
      rotation: 0,
      scale: 0.15,
      anchor: new Point(128, 240),
    }

    const markers: google.maps.Marker[] = []

    // Tried to use AdvancedMarkerElement by following the documentation
    // but the marker was not showing
    // see: https://developers.google.com/maps/documentation/javascript/advanced-markers/add-marker
    markers.push(
      new Marker({
        map: this.instance,
        position: { lat: 46.184_669_1, lng: 6.139_683_9 },
        icon,
        title: 'Genève',
      }),
      new Marker({
        map: this.instance,
        position: { lat: 46.516_909_3, lng: 6.633_214 },
        icon,
        title: 'Lausanne',
      }),
    )

    // Fit the map to the bounds of the markers
    for (const marker of markers) {
      bounds.extend(marker.getPosition()!)
    }

    this.instance.fitBounds(bounds)
  },
}))
