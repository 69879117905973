import rosetta from 'rosetta'
import en from '~/i18n/en'
import fr from '~/i18n/fr'

const messages = {
  en,
  fr,
}

const defaultLocale = 'en'

export function useI18n(
  locale: string | (() => string) = () => document.documentElement.lang,
) {
  const locales = Object.keys(messages)
  const i18n = rosetta(messages)
  const currentLocale = typeof locale === 'function' ? locale() : locale
  const matchingLocale = locales.find(
    (locale) => locale === currentLocale.split('-')[0],
  )
  i18n.locale(matchingLocale || defaultLocale)
  return {
    ...i18n,
    t: (
      key: string | (string | number)[],
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      parameters?: any[] | Record<string, any> | undefined,
      lang?: string | undefined,
    ) => {
      const value = i18n.t(key, parameters, lang)
      if (value === '') return typeof key === 'string' ? key : key.join('')
      return value
    },
  }
}
