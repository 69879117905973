import { defineComponent } from '~/scripts/utils/alpine'
import { getFileSize, uploadFile } from '~/scripts/utils/file'
import type { Upload } from '~/scripts/utils/file'

interface FileUploadOptions {
  url: string
  max?: number
}

/**
 * File upload component with drag & drop support
 * @example Single file
    <div x-data="FileUpload">
      <input type="file" required name="upload_foo" @change="onChange" />
    </div>

 * @example Multiple files, max 5 files, not required
    <div x-data="FileUpload({ max: 5 })">
      <input type="file" name="upload_foo" multiple @change="onChange" />
    </div>
 */
export default defineComponent((options?: FileUploadOptions) => ({
  name: 'file',
  input: undefined as HTMLInputElement | undefined,
  multiple: false,
  required: false,
  uploads: [] as Upload[],
  max: options?.max ?? 10,
  dropZoneActive: false,
  get uploadedFiles() {
    return this.uploads.filter((upload) => upload.state === 'successful')
  },
  get canAddFiles() {
    let total = 0
    for (const upload of this.uploads) {
      if (upload.pending || upload.state === 'successful') {
        total += 1
      }
    }
    return total < this.max
  },
  async init() {
    if (!options?.url) {
      throw new Error('No upload URL provided')
    }

    this.$root.classList.add('relative')
    const input =
      this.$root.querySelector<HTMLInputElement>('input[type="file"]')
    if (!input) {
      throw new Error('No file input found')
    }

    this.input = input
    input.classList.add('sr-only')
    this.multiple = input.multiple
    if (!this.multiple) {
      this.max = 1
    }

    this.required = input.required
    input.required = false

    if (input.name) {
      this.name = input.name
      // Remove name from the input as we don't want to include it in the form
      // submit
      input.name = this.name + '_file'
    }

    // Wait for next tick otherwise event listeners won't work
    await this.$nextTick()

    this.$root.innerHTML += `
      <div
        data-lenis-prevent
        class="relative not-prose p-4 max-h-96 overflow-auto bg-white rounded-[5px] border border-dashed border-tundora-200 transition @container/file"
        :class="{ '!bg-amaranth-50': dropZoneActive }"
        @drop.prevent="onDrop"
        @dragover.prevent="dropZoneActive = true"
        @dragleave="dropZoneActive = false"
      >
        <template x-if="required && uploadedFiles.length < 1">
          <input x-init="initRequiredField" required :name="getInputName" class="absolute inset-0 opacity-0" />
        </template>
        <p class="relative text-center py-4">
          ${this.$i18n.t('upload.label')} <label for="${input.id}" class="underline">${this.$i18n.t('upload.labelBrowse')}</label>
        </p>
        <ul x-auto-animate class="relative grid grid-cols-1 gap-3 items-start @3xl/file:grid-cols-2">
          <template x-for="(upload, index) in uploads" :key="upload.id">
            <li class="flex items-start bg-white border border-tundora-200 shadow rounded-xl p-4 gap-3" :class="{ 'col-span-2': uploads.length < 2 }">
              <div>
                <i
                  class="size-6 flex items-center justify-center text-white rounded-full"
                  :class="getIconClass(upload)"
                >
                  <template x-if="upload.state === 'failed'">
                    <iconify-icon icon="ph:x"></iconify-icon>
                  </template>
                  <template x-if="upload.state === 'successful'">
                    <iconify-icon icon="ph:check"></iconify-icon>
                  </template>
                  <template x-if="upload.state === 'started'">
                    <iconify-icon icon="svg-spinners:bars-rotate-fade"></iconify-icon>
                  </template>
                </i>
              </div>

              <div class="min-w-0 grow">
                <p x-text="upload.file.name" class="truncate"></p>
                <p x-text="getFileSize(upload.file)" class="opacity-50 text-xs"></p>
                <template x-if="upload.state === 'failed'">
                  <p x-text="upload.error.message" class="text-red-500 text-xs"></p>
                </template>
                <template x-if="upload.state === 'successful'">
                  <input type="hidden" :name="getInputName" :value="upload.data.filename" />
                </template>
              </div>
              <div class="flex gap-2 items-center">
                <template x-if="upload.state === 'started'">
                  <button type="button" aria-label="${this.$i18n.t('abort')}" class="size-6 flex items-center justify-center border border-tundora-200 rounded-full" @click="abort(upload.id)">
                    <iconify-icon icon="ph:trash"></iconify-icon>
                  </button>
                </template>
                <template x-if="upload.state !== 'started'">
                  <button type="button" aria-label="${this.$i18n.t('remove')}" class="size-6 flex items-center justify-center border border-tundora-200 rounded-full" @click="remove(upload.id)">
                    <iconify-icon icon="ph:trash"></iconify-icon>
                  </button>
                </template>
              </div>
            </li>
          </template>
        </ul>
      </div>
    `
  },
  getInputName() {
    return this.name + (this.multiple ? `[]` : '')
  },
  getIconClass(upload: Upload) {
    return {
      'bg-red-500': upload.state === 'failed',
      'bg-green-500': upload.state === 'successful',
      'bg-blue-500': upload.state === 'started',
    }
  },
  getFileSize,
  initRequiredField() {
    if (this.$el instanceof HTMLInputElement) {
      this.$el.setCustomValidity(
        this.multiple
          ? this.$i18n.t('upload.requiredMultiple')
          : this.$i18n.t('upload.requiredSingle'),
      )
    }
  },
  remove(id: Upload['id']) {
    this.uploads = this.uploads.filter((upload) => upload.id !== id)
  },
  abort(id: Upload['id']) {
    const upload = this.uploads.find((upload) => upload.id === id)
    if (upload) {
      upload.abort()
      this.remove(id)
    }
  },
  handleFiles(files: FileList) {
    for (const file of files) {
      if (this.canAddFiles) {
        this.uploads.unshift(uploadFile(options!.url, file))
      } else {
        this.$toast.error({
          message: this.$i18n.t('upload.maxFilesReached', { value: this.max }),
          error: new Error('Max files reached'),
        })
        return // Stop adding files
      }
    }
  },
  onChange(event: Event) {
    if (event.target instanceof HTMLInputElement && event.target.files) {
      this.handleFiles(event.target.files)
      event.target.value = '' // Reset the input
    }
  },
  onDrop(event: DragEvent) {
    if (this.input?.disabled) return
    this.dropZoneActive = false
    if (event.dataTransfer) {
      this.handleFiles(event.dataTransfer.files)
    }
  },
}))
