export default {
  abort: 'Annuler',
  alumni: {
    invalidCode: "Le code n'est pas valide.",
  },
  error: 'Une erreur est survenue.',
  goToPage: 'Aller à la page {{value}}',
  invalidPhoneNumber: "Le numéro de téléphone n'est pas valide.",
  upload: {
    maxFilesReached: 'Vous ne pouvez télécharger plus de {{value}} fichier(s).',
    label: 'Glissez-déposez vos fichiers ou',
    labelBrowse: 'Parcourir',
    requiredMultiple: 'Veuillez sélectionner un ou plusieurs fichiers.',
    requiredSingle: 'Veuillez sélectionner un fichier.',
  },
}
