import { defineComponent } from '~/scripts/utils/alpine'

export default defineComponent(() => ({
  open: false,
  toggle() {
    this.open = !this.open
  },
  onClick() {
    const { name } = this.$el.dataset
    if (name === 'studizz') {
      this.openStudizzBot()
    }
    this.open = false
  },
  openStudizzBot() {
    const { StudizzBotOpen } = window as { StudizzBotOpen?: () => void }

    if (StudizzBotOpen) {
      StudizzBotOpen()

      const element = document.querySelector<HTMLElement>(
        '.studizzbot_iframe_class',
      )

      if (element) {
        element.style.opacity = '0'
        element.style.transition = 'opacity 300ms ease-in-out'
        setTimeout(() => {
          element.style.opacity = '1'
        }, 0)
      }
    }
  },
}))
