import { load as loadRecaptcha } from 'recaptcha-v3'
import { defineDirective } from '~/scripts/utils/alpine'

export default defineDirective((input, { expression: key }) => {
  if (!key || typeof key !== 'string') return
  if (!(input instanceof HTMLInputElement)) return
  const form = input.closest('form')
  if (!form) return

  const onFirstInput = async () => {
    try {
      const recaptcha = await loadRecaptcha(key)
      const token = await recaptcha.execute(form.name)
      input.value = token
    } catch {}
  }

  form.addEventListener('input', onFirstInput, { once: true })
})
