import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { defineComponent } from '~/scripts/utils/alpine'
import screens from '#tailwindcss/screens.json'

export default defineComponent(() => ({
  activeSubmenu: undefined as string | undefined,
  topOpacity: 1,
  init() {
    if (this.visible) {
      this.onToggleVisible(true)
    }
    this.$watch('visible', (value: boolean) => {
      this.onToggleVisible(value)
      this.closeSubmenu()
    })

    this.setupTopOpacityHandler()
  },
  setupTopOpacityHandler() {
    // Change top element opacity based on scroll position top prevent
    // intersection with main content
    const progresses = [0, 0, 0, 0]
    const scrollers = [
      // <md screen scroll
      this.$refs.menuMobile,
      // >=md & <lg screen scroll
      this.$refs.menuDesktop,
      // >=lg screen scrollers
      this.$refs.menuDesktopTrainings,
      this.$refs.menuDesktopSchool,
    ]
    for (const scroller of scrollers) {
      const index = scrollers.indexOf(scroller)
      ScrollTrigger.create({
        scroller,
        start: 0,
        end: 50,
        onUpdate: (self) => {
          progresses[index] = self.progress

          // Compute opacity according to progress
          let opacity = 1

          if (window.matchMedia(`(min-width: ${screens.lg})`).matches) {
            opacity = 1 - Math.max(progresses[2], progresses[3])
          } else if (window.matchMedia(`(min-width: ${screens.md})`).matches) {
            opacity = 1 - progresses[1]
          } else {
            opacity = 1 - progresses[0]
          }
          this.topOpacity = opacity
        },
      })
    }
  },
  get visible() {
    return this.$store.ui.menuVisible
  },
  set visible(value: boolean) {
    this.$store.ui.setMenuVisible(value)
  },
  toggleVisible() {
    this.visible = !this.visible
  },
  closeSubmenu() {
    this.activeSubmenu = undefined
  },
  onToggleVisible(value: boolean) {
    gsap.killTweensOf(this.$refs.menu)

    const tl = gsap.timeline({
      onStart: () => {
        gsap.set(this.$refs.menu, { autoAlpha: 1 })
      },
      onComplete: () => {
        if (!value) {
          gsap.set(this.$refs.menu, { autoAlpha: 0 })
        }
      },
    })

    if (value) {
      tl.fromTo(
        this.$refs.menu,
        {
          clipPath: this.getClipPath(false),
        },
        {
          clipPath: this.getClipPath(true),
          duration: 0.7,
          ease: 'power3.inOut',
        },
      )
    } else {
      tl.to(this.$refs.menu, {
        clipPath: this.getClipPath(false),
        duration: 0.6,
        ease: 'power3.inOut',
      })
    }
  },
  getClipPath(visible: boolean = false) {
    const lgScreen = window.matchMedia(`(min-width: ${screens.lg}px)`).matches
    const radius = lgScreen ? '10px' : '6px'
    if (visible) {
      return `inset(-${radius} -${radius} -${radius} -${radius} round ${radius})`
    } else {
      const menuToggler = document.querySelector('[x-ref="menuToggler"]')
      if (!menuToggler) return `inset(0 0 0 0 round 0)`

      const rect = menuToggler.getBoundingClientRect()
      const shrink = 2 // shrink X pixels from each side
      const top = rect.y + shrink
      const right = document.documentElement.clientWidth - rect.right + shrink
      return `inset(
          ${top}px
          ${right}px
          ${window.innerHeight - (top + rect.height - shrink * 2)}px
          ${document.documentElement.clientWidth - (right + rect.width - shrink * 2)}px
          round ${radius}
        )`
    }
  },
}))
