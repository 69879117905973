/**
 * Normalize a string
 */
export function normalizeString(value: string) {
  return value
    .normalize('NFD')
    .replaceAll(/[\u0300-\u036F]|-/g, '')
    .toLowerCase()
    .trim()
}
