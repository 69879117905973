import { gsap } from 'gsap'
import { defineComponent } from '~/scripts/utils/alpine'
import screens from '#tailwindcss/screens.json'

export default defineComponent(() => ({
  init() {
    const { videoContainer } = this.$refs
    const matchMedia = gsap.matchMedia()
    matchMedia.add(`(min-width: ${screens.xl})`, () => {
      const radius = 30
      const startWidth = 900
      const scale = () => startWidth / this.$el.clientWidth

      gsap
        .timeline({
          scrollTrigger: {
            trigger: videoContainer,
            start: 'top 80%',
            end: 'bottom center',
            scrub: 1,
            invalidateOnRefresh: true,
          },
        })
        .fromTo(
          videoContainer,
          {
            scale,
            borderRadius: () => radius / scale(),
            translateY: -76,
          },
          {
            scale: 1,
            borderRadius: () =>
              // The video can't be larger than 1920px so if the screen is
              // larger than that, we keep some border radius
              window.matchMedia(`(max-width: 1920px)`).matches ? 0 : radius,
            translateY: 0,
            ease: 'none',
          },
        )
    })
  },
}))
