/**
 * Load a script programmatically
 */
export function loadScript(source: string) {
  return new Promise(function (resolve, reject) {
    let script = document.querySelector<HTMLScriptElement>(
      `script[src="${source}"]`,
    )
    if (!script) {
      script = document.createElement('script')
      script.type = 'text/javascript'
      script.async = true
      script.src = source
      document.head.append(script)
    } else if (script.getAttribute('loaded')) {
      resolve(script)
      return
    }

    script.addEventListener('error', reject)
    script.addEventListener('abort', reject)
    script.addEventListener('load', () => {
      script?.setAttribute('data-loaded', 'true')
      resolve(script)
    })
  })
}

/**
 * A simple promise that resolves after a given time
 * @example
 * async function test() {
 *   console.log('before wait')
 *   await wait(1000)
 *   console.log('waited 1 second')
 * }
 */
export function wait(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

export default { loadScript, wait }
