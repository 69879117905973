import { gsap } from 'gsap'
import { defineComponent } from '~/scripts/utils/alpine'
import { useElementSize } from '~/scripts/composables/useElementSize'

export default defineComponent(() => ({
  init() {
    // Header height
    useElementSize(this.$el, (size) => {
      this.$store.ui.setHeaderHeight(size.height)
    })

    // Logo height change on scroll
    gsap.to(this.$refs.logo, {
      scale: 1,
      force3D: false, // 3d transform causes blurry svg on Safari
      scrollTrigger: {
        start: 0,
        end: '+=200',
        scrub: 0.8,
        invalidateOnRefresh: true,
      },
    })
  },
}))
