import { defineComponent } from '~/scripts/utils/alpine'
import EmblaCarousel, {
  EmblaCarouselType,
  EmblaOptionsType,
} from 'embla-carousel'
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures'

/**
 * Carousel component
 * @example Minimal:
    <div x-data="Carousel">
      <div x-bind="carouselViewport">
        <div x-bind="carouselContainer">
          <div x-bind="carouselSlide">Slide 1</div>
          <div x-bind="carouselSlide">Slide 2</div>
          <div x-bind="carouselSlide">Slide 3</div>
        </div>
      </div>
      <button x-bind="carouselPrev" aria-label="Previous slide">Prev</button>
      <button x-bind="carouselNext" aria-label="Next slide">Next</button>
      <div x-bind="carouselPagination" class="space-x-2.5"></div>
    </div>
 *
 * @example With options (see: https://www.embla-carousel.com/api/options/):
    <div x-data="Carousel({ align: 'start', skipSnaps: true })">
 */
export default defineComponent((options?: EmblaOptionsType) => ({
  id: '',
  carousel: undefined as EmblaCarouselType | undefined,
  canScrollPrev: false,
  canScrollNext: false,
  currentScrollSnap: 0,
  scrollSnapList: [] as number[],
  slidesInView: [] as number[],
  busy: false,
  init() {
    this.id = this.$id('carousel')
    this.$root.id = this.id
  },
  update() {
    const { carousel } = this
    if (!carousel) return
    this.canScrollPrev = carousel.canScrollPrev() ?? false
    this.canScrollNext = carousel.canScrollNext() ?? false
    this.scrollSnapList = carousel.scrollSnapList()
    this.currentScrollSnap = carousel.selectedScrollSnap()
  },
  scrollTo(snapIndex: number) {
    if (!this.carousel) return
    this.carousel.scrollTo(snapIndex)
  },
  carouselViewport: {
    'x-init'() {
      const carousel = EmblaCarousel(this.$el, options, [WheelGesturesPlugin()])
      carousel
        .on('init', () => {
          this.update()
        })
        .on('select', () => {
          this.update()
        })
        .on('reInit', () => {
          this.update()
        })
        .on('scroll', () => {
          this.busy = true
        })
        .on('settle', () => {
          this.busy = false
        })
        .on('slidesInView', () => {
          this.slidesInView = carousel.slidesInView()
        })

      this.carousel = carousel
    },
    ':id'() {
      return this.id + '-viewport'
    },
    ':aria-atomic'() {
      return true
    },
    ':aria-live'() {
      return 'polite'
    },
    ':aria-busy'() {
      return this.busy
    },
  },
  carouselContainer: {
    ':role'() {
      return 'presentation'
    },
  },
  carouselSlide: {
    ':role'() {
      return 'group'
    },
    ':aria-hidden'() {
      if (!this.carousel) return false
      const slide = this.$el as HTMLElement & { _x_hidden?: boolean }
      const index = this.carousel.slideNodes().indexOf(slide)

      const previousValue = slide._x_hidden
      const value = !this.slidesInView.includes(index)

      // Store the value to compare it later
      slide._x_hidden = value

      // If a [aria-hidden="true"] element contains focusable elements — such
      // as <input> and <textarea> — users are still able to reach them by Tab,
      // but screen readers won't explain what they are.
      // To solve this problem, we can update the tabindex.
      // see: https://dequeuniversity.com/rules/axe/4.9/aria-hidden-focus
      if (value !== previousValue) {
        for (const element of slide.querySelectorAll<HTMLElement>(
          'a, button, textarea, input, select, iframe',
        )) {
          element.tabIndex = value ? -1 : 0
        }
      }
      return value
    },
  },
  carouselPrev: {
    ':aria-controls'() {
      return this.carouselViewport[':id'].call(this)
    },
    ':disabled'() {
      return !this.canScrollPrev
    },
    '@click'() {
      this.carousel?.scrollPrev()
    },
  },
  carouselNext: {
    ':aria-controls'() {
      return this.carouselViewport[':id'].call(this)
    },
    ':disabled'() {
      return !this.canScrollNext
    },
    '@click'() {
      this.carousel?.scrollNext()
    },
  },
  carouselPagination: {
    'x-html'() {
      return this.scrollSnapList
        .map((_position, index) => {
          return `<button x-bind="carouselPaginationItem" data-index="${index}"></button>`
        })
        .join('')
    },
  },
  carouselPaginationItem: {
    ':aria-label'() {
      return this.$i18n.t('goToPage', { value: this.$el.dataset.index })
    },
    ':aria-selected'() {
      return (
        this.currentScrollSnap ===
        Number.parseInt(this.$el.dataset.index || '0')
      )
    },
    ':class'() {
      return 'size-3 bg-current rounded-full aria-selected:text-amaranth-600 transition-colors duration-300'
    },
    '@click'() {
      this.scrollTo(Number.parseInt(this.$el.dataset.index || '0'))
    },
  },
}))
