import { Toast, ToastWithError } from '~/scripts/store/toast'

export function useToast(toastStore = window.$store?.toast) {
  if (!toastStore) throw new Error('toastStore is required')
  return {
    /**
     * Add a toast info
     * @returns Id of the toast
     */
    info(detail: Omit<Toast, 'id' | 'type'>) {
      return toastStore.add({ type: 'info', ...detail })
    },
    /**
     * Add a toast success
     * @returns Id of the toast
     */
    success(detail: Omit<Toast, 'id' | 'type'>) {
      return toastStore.add({ type: 'success', ...detail })
    },
    /**
     * Add a toast error
     * @returns Id of the toast
     */
    error(detail: Omit<ToastWithError, 'id' | 'type'>) {
      return toastStore.add({ type: 'error', ...detail })
    },
    /**
     * Add a toast warning
     * @returns Id of the toast
     */
    warning(detail: Omit<Toast, 'id' | 'type'>) {
      return toastStore.add({ type: 'warning', ...detail })
    },
  }
}
