import { defineDirective } from '~/scripts/utils/alpine'
import { scrollTo } from '~/scripts/utils/scroll'

/**
 * x-scroll-to directive
 * @example Scroll to y-coord
 * <button x-scroll-to="0">Scroll to top</button>
 * @example Scroll to a target (selector)
 * <button x-scroll-to=".hero">Scroll to hero</button>
 */
export default defineDirective((element, { expression }) => {
  element.addEventListener('click', (event) => {
    event.preventDefault()
    const target = Number.isNaN(Number.parseFloat(expression))
      ? expression
      : Number.parseFloat(expression)
    scrollTo(target)
  })
})
