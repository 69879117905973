import { defineComponent } from '~/scripts/utils/alpine'

export interface AccordionOptions {
  multiple?: boolean
}

/**
 * Accordion component
 * @example Minimal:
    <div x-data="Accordion" x-bind="accordion">
			<button x-bind="accordionTrigger" class="group/trigger w-full flex items-center justify-between gap-4 text-left text-balance">
				Panel 1
				<span class="text-4xl group-aria-expanded/trigger:hidden">+</span>
				<span class="text-4xl hidden group-aria-expanded/trigger:block">-</span>
			</button>
			<div x-cloak x-bind="accordionPanel">Panel 1</div>
			<button x-bind="accordionTrigger" class="group/trigger w-full flex items-center justify-between gap-4 text-left text-balance">
				Panel 2
				<span class="text-4xl group-aria-expanded/trigger:hidden">+</span>
				<span class="text-4xl hidden group-aria-expanded/trigger:block">-</span>
			</button>
			<div x-cloak x-bind="accordionPanel">Panel 2</div>
			<button x-bind="accordionTrigger" class="group/trigger w-full flex items-center justify-between gap-4 text-left text-balance">
				Panel 3
				<span class="text-4xl group-aria-expanded/trigger:hidden">+</span>
				<span class="text-4xl hidden group-aria-expanded/trigger:block">-</span>
			</button>
			<div x-cloak x-bind="accordionPanel">Panel 3</div>
		</div>
 *
 * @example With options:
      <div x-data="Accordion({ multiple: true })" x-bind="accordion">
 */
export default defineComponent((options?: AccordionOptions) => ({
  active: undefined as string | string[] | undefined,
  getIndex(element: HTMLElement) {
    const type = element.getAttribute('x-bind')
    return [...this.$root.querySelectorAll(`[x-bind="${type}"]`)].indexOf(
      element,
    )
  },
  select(id: string) {
    if (options?.multiple) {
      if (this.active === undefined) {
        this.active = []
      }

      if (Array.isArray(this.active)) {
        if (this.active.includes(id)) {
          this.active = this.active.filter((item) => item !== id)
        } else {
          this.active.push(id)
        }
      }
    } else {
      this.active = this.active === id ? undefined : id
    }
  },
  isSelected(id: string) {
    if (options?.multiple && Array.isArray(this.active)) {
      return this.active.includes(id)
    }
    return this.active === id
  },
  accordion: {
    'x-id'() {
      return ['trigger', 'panel']
    },
  },
  accordionTrigger: {
    ':id'() {
      return this.$id('trigger', this.getIndex(this.$el).toString())
    },
    ':type'() {
      return 'button'
    },
    ':aria-controls'() {
      return this.$id('panel', this.getIndex(this.$el).toString())
    },
    ':aria-expanded'() {
      const id = this.$el.getAttribute('aria-controls')
      if (!id) return false
      return this.isSelected(id)
    },
    '@click'() {
      const id = this.$el.getAttribute('aria-controls')
      if (id) {
        this.select(id)
      }
    },
  },
  accordionPanel: {
    ':id'() {
      return this.$id('panel', this.getIndex(this.$el).toString())
    },
    ':role'() {
      return 'region'
    },
    ':aria-labelledby'() {
      return this.$id('trigger', this.getIndex(this.$el).toString())
    },
    'x-show'() {
      return this.isSelected(this.$el.id)
    },
    'x-collapse'() {},
  },
}))
