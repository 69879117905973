import { debounce } from 'radash'
import { defineDirective } from '~/scripts/utils/alpine'

function update(select: HTMLSelectElement) {
  if (!select.parentElement) return

  const option = select.options[select.selectedIndex]
  const text = option.text
  const ghost = `
    <select class="${select.classList.toString()}">
      <option>${text}</option>
    </select>
  `
  const ghostElement = document.createElement('div')
  ghostElement.style.position = 'absolute'
  ghostElement.style.top = '0'
  ghostElement.style.left = '0'
  ghostElement.style.pointerEvents = 'none'
  ghostElement.style.visibility = 'hidden'

  ghostElement.innerHTML = ghost
  select.parentElement.append(ghostElement)
  const width = ghostElement.clientWidth
  ghostElement.remove()
  select.style.width = `${width}px`
}

/**
 * x-select-autosize directive
 * @example
 * <select x-select-autosize></select>
 */
export default defineDirective((element) => {
  // check if element is a select element
  if (!(element instanceof HTMLSelectElement)) return

  update(element)

  element.addEventListener('change', () => {
    update(element)
  })

  window.addEventListener(
    'resize',
    debounce({ delay: 200 }, () => update(element)),
  )
})
