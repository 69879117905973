import Alpine from 'alpinejs'
import { ofetch } from 'ofetch'
import { useI18n } from '~/scripts/composables/useI18n'

/**
 * Get the size of a file in a human-readable format
 * @param file - The file to get the size of
 * @returns The size of the file in a human-readable format
 */
export function getFileSize(file: File): string {
  let size = file.size
  let unit = 'B'
  if (size > 1024) {
    size /= 1024
    unit = 'KB'
  }
  if (size > 1024) {
    size /= 1024
    unit = 'MB'
  }
  if (size > 1024) {
    size /= 1024
    unit = 'GB'
  }
  return `${size.toFixed(2)} ${unit}`
}

export type UploadState = 'started' | 'failed' | 'successful' | 'aborted'

export interface UploadSuccessResponse {
  success: true
  data: {
    filename: string
    original_filename: string
  }
}

export interface UploadFailedResponse {
  success: false
  file: Record<string, string>
}

export function uploadFile(url: string, file: File) {
  const form = new FormData()
  form.append('file', file, file.name)

  const controller = new AbortController()

  const request = ofetch<UploadSuccessResponse>(url, {
    method: 'POST',
    body: form,
    signal: controller.signal,
  })

  const instance = Alpine.reactive({
    id: file.name + '_' + Date.now(),
    file,
    state: 'started' as UploadState,
    abort: () => {
      controller.abort('Upload aborted')
    },
    get pending() {
      return instance.state === 'started'
    },
    request,
    data: undefined as UploadSuccessResponse['data'] | undefined,
    error: undefined as { message: string; statusCode: number } | undefined,
  })

  const { t } = useI18n()

  request
    .then((value) => {
      instance.data = value.data
      instance.state = 'successful'
      return value
    })
    .catch((error) => {
      if (controller.signal.aborted) return
      instance.error = {
        message: error.response.statusText || t('error'),
        statusCode: error.response.status || 500,
      }

      if (
        typeof error.response._data === 'object' &&
        'data' in error.response._data
      ) {
        instance.error.message = Object.values(
          error.response._data.data.file,
        )[0] as string
      } else if (error.response.statusText) {
        instance.error.message = error.response.statusText
      }

      instance.state = 'failed'
      return error
    })

  controller.signal.addEventListener('abort', () => {
    instance.state = 'aborted'
  })

  return instance
}

export type Upload = ReturnType<typeof uploadFile>
