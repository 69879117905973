import Cookie from 'universal-cookie'
import { FetchError } from 'ofetch'
import { tryit } from 'radash'
import { defineComponent } from '~/scripts/utils/alpine'
import {
  SubmitRegistrationError,
  getSchoolLevel2,
  submitRegistration,
} from '~/scripts/api/registrations'
import { formToObject, resetSelectOptions } from '~/scripts/utils/form'
import { scrollTo } from '~/scripts/utils/scroll'
import { SHA256Encrypt } from '~/scripts/utils/crypto'

function rem(px: number) {
  return `${px / 16}rem`
}

export interface RegistrationFormOptions {
  redirectionUrl: string
  watchedFields?: string[]
}

export default defineComponent((options: RegistrationFormOptions) => {
  const watchedFields = options.watchedFields ?? []
  const values: Record<string, string | number> = {}
  if (watchedFields.length > 0) {
    for (const field of watchedFields) {
      values[field] = ''
    }
  }

  return {
    currentStep: 0,
    watchedFields,
    values,
    submitLoading: false,
    submitError: undefined as FetchError<SubmitRegistrationError> | undefined,
    init() {
      if (!options.redirectionUrl) {
        throw new Error('Missing redirection url')
      }

      this.$watch('currentStep', (value) => {
        const { timeline } = this.$refs
        if (timeline) {
          // Auto center to the active step
          const button =
            timeline.querySelectorAll<HTMLButtonElement>(`button`)[value]
          if (button) {
            // Scroll value so button is centered in the timeline scrollable element
            const left =
              button.offsetLeft -
              timeline.clientWidth / 2 +
              button.clientWidth / 2

            timeline.scrollTo({ left, behavior: 'smooth' })
          }
        }

        // Scroll to the top of the form
        scrollTo(this.$root)

        this.updateNextButton()
      })

      // Watch for changes in watched fields
      this.$root.addEventListener('input', async (event: Event) => {
        const target = event.target as
          | HTMLInputElement
          | HTMLTextAreaElement
          | HTMLSelectElement

        if (watchedFields.length > 0 && watchedFields.includes(target.name)) {
          this.values[target.name] = target.value
        }

        await this.$nextTick()

        // Check if the name matches school\d+_level1
        if (
          target instanceof HTMLSelectElement &&
          /^school\d+_level$/.test(target.name)
        ) {
          this.updateSchoolLevel2Options(target)
        }
      })
    },
    async updateSchoolLevel2Options(target: HTMLSelectElement) {
      const number = target.name.match(/\d+/)?.[0]
      const target2 = target.form?.querySelector<HTMLSelectElement>(
        `select[name="school${number}_level2"]`,
      )

      if (target2) {
        try {
          resetSelectOptions(target2)
          if (!target.value) {
            // No option to fetch if no school_level selected
            return
          }

          target2.disabled = true
          target2.style.cursor = 'wait'
          const response = await getSchoolLevel2(target.value)
          for (const [value, text] of Object.entries(response.data)) {
            const option = document.createElement('option')
            option.value = value
            option.text = text
            target2.add(option)
          }
        } catch (error) {
          this.$toast.error({
            message: this.$i18n.t('error'),
            error: error instanceof Error ? error : new Error('Unknown error'),
          })
          // Reset the field options
          resetSelectOptions(target2)
        } finally {
          target2.disabled = false
          target2.style.cursor = ''
        }
      }
    },
    updateNextButton() {
      const section = this.getSection(this.currentStep)
      if (section && this.$refs.nextButton) {
        const button = section.querySelector('[type="submit"]')
        if (button) {
          const label = this.$refs.nextButton.querySelector('.button-label')
          if (label) {
            label.textContent = button.textContent
          }
        }
      }
    },
    getSection(index: number) {
      return this.$root.querySelector<HTMLElement>(
        `section[x-bind="formSection"]:nth-child(${index + 1})`,
      )
    },
    async onSubmit() {
      const sections = this.$root.querySelectorAll('[x-bind="formSection"]')

      const totalSteps = sections.length
      if (this.currentStep < totalSteps - 1) {
        this.currentStep++
        await this.$nextTick()
        scrollTo(this.$root)
      } else {
        this.onComplete()
      }
    },
    onNext() {
      const section = this.getSection(this.currentStep)
      if (!section) return

      // Find a submit button in the form
      const button = section.querySelector<HTMLButtonElement>(
        'form button[type="submit"]',
      )

      if (button) {
        // Trigger form submission
        button.click()
      } else {
        this.currentStep++
      }
    },
    async onComplete() {
      try {
        this.submitLoading = true
        this.submitError = undefined
        const forms = this.$root.querySelectorAll<HTMLFormElement>(
          '[x-bind="formSection"] form',
        )
        // Merge all form data
        let data: Record<string, string | string[]> = {}
        for (const form of forms) {
          data = Object.assign(data, formToObject(form, { includeEmpty: true }))
        }

        // Get alumni code from cookies
        const cookieStore = new Cookie({
          path: '/',
          sameSite: 'strict',
          secure: true,
        })
        const alumniCode = cookieStore.get('alumni') as string | undefined

        const response = await submitRegistration(data, !!alumniCode)
        this.sendAnalyticsEvents(data)
        window.location.href =
          options.redirectionUrl + '?registration_key=' + response.data.id
      } catch (error_) {
        const error = error_ as FetchError<SubmitRegistrationError>
        this.submitError = error

        // Display the error messages from the server
        if (error.data) {
          const errors: string[] = []
          if (error.data.data) {
            for (const fieldErrors of Object.values(error.data.data)) {
              errors.push(...Object.values(fieldErrors))
            }
          }
          const message =
            errors.length > 1
              ? `<ul class="list-disc pl-4"><li>${errors.join('</li><li>')}</li></ul>`
              : errors[0]

          this.$toast.error({ message, error })
        }
        // Display a generic error message
        else {
          this.$toast.error({
            message: this.$i18n.t('error'),
            error,
          })
        }
      } finally {
        this.submitLoading = false
      }
    },
    async sendAnalyticsEvents(data: Record<string, string | string[]>) {
      const {
        type,
        program,
        programDetails,
        schoolCities,
        backToSchool,
        backToSchoolYear,
        amount,
      } = this.$root.dataset
      const source = window.sessionStorage.getItem('utm_source')

      window.dataLayer?.push({
        event: 'conversion',
        reference: Date.now().toString(),
        mail: data.student_email,
        path: window.location.pathname,
        formCategory: type,
        program,
        programDetails,
        campus: 'CREA',
        schoolCity: schoolCities,
        backToSchool,
        backToSchoolYear,
        amount,
        source,
      })

      const [_error, uid] = await tryit(SHA256Encrypt)(
        data.student_email as string,
      )

      window.eaPush?.({
        uid,
        ref: Date.now(),
        estimate: '1',
        type: [type, programDetails].join(' ').trim(),
        path: window.location.pathname,
        pagegroup: 'FORMULAIRE',
        'ville-ecole': schoolCities,
        campus: 'CREA',
        'annee-entree': backToSchoolYear,
        'date-entree': backToSchool,
        'choix-programme': programDetails,
        source,
      })
    },
    formSection: {
      ':class'() {
        const index = [...this.$el.parentElement!.children].indexOf(this.$el)
        const { currentStep } = this

        return {
          '!absolute inset-x-0 top-0 scale-75 opacity-0 lg:scale-100':
            currentStep !== index,
          'pointer-events-none': currentStep < index,
          'lg:opacity-100': currentStep >= index,
          'group/section-completed': currentStep > index,
          'lg:hover:-rotate-2': currentStep > index && index === 0,
          'lg:hover:-rotate-6': currentStep > index && index !== 0,
        }
      },
      ':style'() {
        const index = [...this.$el.parentElement!.children].indexOf(this.$el)
        const { currentStep } = this
        let y = rem(30 * (currentStep - index) - 30)
        if (currentStep === index) {
          y = rem(30)
        } else if (index > currentStep) {
          y = rem(60)
        }

        return {
          '--tw-translate-x': rem((currentStep - index) * -30),
          '--tw-translate-y': y,
        }
      },
    },
  }
})
