import { defineComponent } from '~/scripts/utils/alpine'
import { useElementSize } from '~/scripts/composables/useElementSize'
import type { CountryCode, CountryCallingCode } from 'libphonenumber-js'

export interface InputTelOptions {
  country?: string
}

/**
 * InputTel component
 * Augments an input[type="tel"] with a country code selector + phone number validation and formatting
 * @example Default:
    <div x-data="InputTel">
      <input type="tel" />
    </div>
 *
 * @example With specific country code:
    <div x-data="InputTel({ country: 'FR' })">
 */
export default defineComponent((options?: InputTelOptions) => ({
  options: [] as { value: CountryCode; code: CountryCallingCode }[],
  country: (options?.country ?? 'CH') as CountryCode,
  input: undefined as HTMLInputElement | undefined,
  value: '',
  async init() {
    // Get input element
    const input =
      this.$root.querySelector<HTMLInputElement>('input[type="tel"]')
    if (!input) return

    // Load libphonenumber-js
    const phoneNumber = await import('libphonenumber-js/min')
    const countries = phoneNumber.getCountries()

    // Build list of options
    this.options = countries.map((country) => ({
      value: country,
      code: phoneNumber.getCountryCallingCode(country),
    }))

    input.addEventListener('input', () => {
      this.value = input.value
    })

    const update = () => {
      try {
        const result = phoneNumber.parsePhoneNumber(this.value, this.country)
        if (result.isValid()) {
          input.setCustomValidity('')
          input.value = result.formatInternational()
        } else {
          throw new Error('Invalid phone number')
        }
      } catch {
        input.setCustomValidity(this.$i18n.t('invalidPhoneNumber') as string)
      }
    }

    this.$watch('country', () => {
      update()
    })

    this.$watch('value', () => {
      update()
    })

    this.$root.classList.add('form-input-group')
    this.$root.insertAdjacentHTML(
      'afterbegin',
      `<div
        x-cloak
        x-data="Combobox({ value: country })"
        x-bind="root"
        class="!absolute inset-y-0 left-0 px-3 py-2"
        @combobox:input="country = $event.detail.value"
      >
        <button x-bind="button">
          <iconify-icon :icon="getIcon(value)" class="w-auto align-middle"></iconify-icon>
					<iconify-icon icon="solar:alt-arrow-down-outline" class="align-middle"></iconify-icon>
        </button>
        <div data-lenis-prevent x-bind="menu" class="w-24">
          <div class="p-2 flex items-center gap-2 border-b border-tundora-200">
            <iconify-icon icon="ph:magnifying-glass"></iconify-icon>
            <input type="search" x-model="search" class="min-w-0 outline-none" />
          </div>
          <div x-ref="options" class="max-h-64 overflow-auto">
            <template x-for="option in options">
              <button type="button" :data-country="option.value" class="p-2 w-full flex items-center gap-2 lining-nums hover:bg-tundora-50" @click="select(option.value)">
                <iconify-icon :icon="getIcon(option.value)" class="w-auto"></iconify-icon> <span x-text="'+' + option.code"></span>
              </button>
            </template>
          </div>
        </div>
      </div>`,
    )
    const combobox = this.$root.children[0]! as HTMLDivElement

    useElementSize(combobox, (size) => {
      input.style.paddingLeft = `${size.width}px`
    })
  },
  getIcon(value: string) {
    if (value === 'CH') return 'flag:ch-1x1'
    if (value === 'GU') return 'flagpack:gu'
    return 'flag:' + value.toLowerCase() + '-4x3'
  },
}))
