/**
 * Set a CSS variable on the root element.
 */
export function setRootCSSVariable(
  name: string,
  value: string | null,
  priority?: string,
) {
  document.documentElement.style.setProperty('--' + name, value, priority)
}
