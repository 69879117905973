import Lenis, { LenisOptions } from 'lenis'

interface UseLenisOptions {
  useTicker?: (lenis: Lenis) => void
}

/**
 * Use Lenis (smooth scroll)
 * @see https://github.com/studio-freight/lenis
 */
export function useLenis(options: UseLenisOptions & LenisOptions = {}) {
  const { useTicker, ...lenisOptions } = options

  const lenis = new Lenis(lenisOptions)
  window.lenis = lenis

  if (useTicker) {
    useTicker(lenis)
  } else {
    function raf(time: number) {
      lenis.raf(time)
      requestAnimationFrame(raf)
    }
    requestAnimationFrame(raf)
  }

  return lenis
}
