import { defineComponent } from '~/scripts/utils/alpine'

/**
 * Helper breakpoints component
 */
export default defineComponent(() => ({
  isVisible: true,
  size: '',
  dragging: false,
  startX: 0,
  startY: 0,
  currentX: 0,
  currentY: 0,
  doubleTapTimeout: undefined as number | undefined,
  init() {
    window.addEventListener('resize', this.updateSize.bind(this))
    this.updateSize()
  },
  updateSize() {
    this.size = `${window.innerWidth}x${window.innerHeight}`
  },
  destroy() {
    window.removeEventListener('resize', this.updateSize)
  },
  onDragStart(event: TouchEvent | MouseEvent) {
    if (this.doubleTapTimeout) {
      this.isVisible = false
    } else {
      this.doubleTapTimeout = setTimeout(() => {
        this.doubleTapTimeout = undefined
      }, 300)
    }

    if ('touches' in event) {
      this.startX = event.touches[0].clientX - this.currentX
      this.startY = event.touches[0].clientY - this.currentY
    } else {
      this.startX = event.clientX - this.currentX
      this.startY = event.clientY - this.currentY
    }

    this.dragging = true
  },
  onDragEnd() {
    this.dragging = false
  },
  onDrag(event: TouchEvent | MouseEvent) {
    if (this.dragging) {
      if ('touches' in event) {
        this.currentX = event.touches[0].clientX - this.startX
        this.currentY = event.touches[0].clientY - this.startY
      } else {
        this.currentX = event.clientX - this.startX
        this.currentY = event.clientY - this.startY
      }
    }
  },
}))
