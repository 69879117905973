import Cookie from 'universal-cookie'
import { defineComponent } from '~/scripts/utils/alpine'
import { checkAlumni } from '~/scripts/api/registrations'
import { wait } from '~/scripts/utils/async'

export default defineComponent(() => ({
  isAlumni: false,
  code: '',
  state: 'initial' as 'initial' | 'loading' | 'success' | 'error',
  errorMessage: '',
  init() {
    const cookieStore = new Cookie({
      path: '/',
      sameSite: 'strict',
      secure: true,
    })

    // Check in the cookies if we have an alumni code
    const value = cookieStore.get('alumni') as string | undefined
    if (value !== undefined) {
      this.isAlumni = true
      this.code = value
      this.state = 'success'
    }

    this.$watch('isAlumni', (value) => {
      // Reset
      if (!value) {
        this.state = 'initial'
      }
    })

    this.$watch('state', async (value) => {
      if (value === 'success') {
        cookieStore.set('alumni', this.code)
        await wait(500)
        window.location.reload()
      } else {
        cookieStore.remove('alumni')
      }
    })
  },
  async onSubmit() {
    try {
      this.state = 'loading'
      const [response] = await Promise.all([checkAlumni(this.code), wait(500)])

      this.state = response.success ? 'success' : 'error'
      if (this.state === 'error') {
        this.errorMessage = this.$i18n.t('alumni.invalidCode')
      }
    } catch {
      this.state = 'error'
      this.errorMessage = this.$i18n.t('error')
    }
  },
}))
