import {
  autoUpdate,
  computePosition,
  flip,
  offset,
  shift,
  Placement,
} from '@floating-ui/dom'
import { defineDirective } from '~/scripts/utils/alpine'

const placements = [
  'top',
  'top-start',
  'top-end',
  'right',
  'right-start',
  'right-end',
  'bottom',
  'bottom-start',
  'bottom-end',
  'left',
  'left-start',
  'left-end',
] as Placement[]

/**
 * tooltip directive
 * @example Will show a tooltip when hovering the element
 * <div x-tooltip="This is a tooltip message">Lorem ipsum</div>
 *
 * @example The tooltip will be placed on the right
 * <div x-tooltip.right="This is a tooltip message">Lorem ipsum</div>
 *
 * @example The tooltip message is a javascript expression
 * <div x-tooltip.eval="`This is a tooltip message ${1 + 1}`">Lorem ipsum</div>
 */
export default defineDirective(
  (element, { expression, modifiers }, { evaluate, cleanup }) => {
    let message: HTMLDivElement
    let cleanupAutoUpdate: (() => void) | undefined
    let hideTimeout: number
    const hideClasses = ['opacity-0', 'scale-50', 'pointer-events-none']
    const placement =
      placements.find((placement) => modifiers.includes(placement)) || 'top'

    async function onMouseEnter() {
      cancelHide()

      if (!message) {
        message = document.createElement('div')
        message.classList.add(
          'absolute',
          'top-0',
          'left-0',
          'z-20',
          'transition',
          'bg-stone-900/80',
          'backdrop-blur-sm',
          'text-base',
          'leading-tight',
          'text-white',
          'shadow',
          'rounded-md',
          'px-3',
          'py-2',
          'max-w-xs',
          'prose',
          'prose-invert',
          ...hideClasses,
        )
        document.body.append(message)
      }

      message.innerHTML = modifiers.includes('eval')
        ? evaluate(expression)
        : expression

      cleanupAutoUpdate = autoUpdate(element, message, async () => {
        const position = await computePosition(element, message, {
          placement,
          middleware: [offset(4), flip({ padding: 8 }), shift({ padding: 8 })],
        })
        Object.assign(message.style, {
          left: `${position.x}px`,
          top: `${position.y}px`,
          transformOrigin: position.placement === 'top' ? 'bottom' : 'top',
        })
      })

      message.classList.remove(...hideClasses)
    }

    function onMouseLeave() {
      hide()
    }

    function cancelHide() {
      clearTimeout(hideTimeout)
    }

    function hide() {
      hideTimeout = setTimeout(() => {
        if (cleanupAutoUpdate) {
          cleanupAutoUpdate()
          cleanupAutoUpdate = undefined
        }
        if (message) {
          message.classList.add(...hideClasses)
        }
      }, 50)
    }

    element.addEventListener('mouseenter', onMouseEnter)
    element.addEventListener('mouseleave', onMouseLeave)

    cleanup(() => {
      cancelHide()
      if (cleanupAutoUpdate) {
        cleanupAutoUpdate()
        cleanupAutoUpdate = undefined
      }

      element.removeEventListener('mouseenter', onMouseEnter)
      element.removeEventListener('mouseleave', onMouseLeave)
    })
  },
)
