import { gsap } from 'gsap'
import { shuffle } from 'radash'
import { defineComponent } from '~/scripts/utils/alpine'

export default defineComponent(() => ({
  selectedCategory: '',
  // To keep a reference to the list and all original elements
  list: undefined as HTMLUListElement | undefined,
  elements: [] as HTMLElement[],
  busy: false, // To disable form fields while animating
  init() {
    const list = this.$root.querySelector<HTMLUListElement>(
      '[x-bind="carouselContainer"]',
    )
    if (!list) throw new Error('List not found')

    // Save list and elements
    this.list = list
    this.elements = [...list.children] as HTMLElement[]

    // Handle category change
    this.$watch(
      'selectedCategory',
      this.onSelectedCategoryChange.bind(this, true),
    )

    this.onSelectedCategoryChange(false)
  },
  async onSelectedCategoryChange(animate: boolean = true) {
    if (!this.list) return

    // Leave animation
    if (animate) {
      this.busy = true
      await this.animateList(this.list, 'leave').then()
    }

    // Render new list
    if (this.selectedCategory === '') {
      this.randomize()
    } else {
      this.filter()
    }

    // Enter animation
    if (animate) {
      await this.animateList(this.list, 'enter').then()
      this.busy = false
    }
  },
  randomize() {
    if (!this.list) return

    const elements = shuffle(this.elements)
    this.renderList(this.list, elements)
  },
  filter() {
    if (!this.list) return

    const elements = this.elements.filter((element) => {
      return element.dataset.category === this.selectedCategory
    })
    this.renderList(this.list, elements)
  },
  animateList(list: HTMLUListElement, action: 'enter' | 'leave') {
    const isEnter = action === 'enter'

    const stagger = {
      each: isEnter ? 0.07 : undefined,
      onStart(this: gsap.core.Tween) {
        const target = this.targets<HTMLElement>()[0]
        target.style.transition = 'none'
      },
      onComplete(this: gsap.core.Tween) {
        const target = this.targets<HTMLElement>()[0]
        target.style.transition = ''
      },
    }

    const elements = list.querySelectorAll('article')

    // Enter animation
    if (isEnter) {
      return gsap.fromTo(
        elements,
        { opacity: 0, x: 120, scale: 1.1 },
        {
          opacity: 1,
          x: 0,
          scale: 1,
          clearProps: 'all',
          duration: 0.7,
          ease: 'back.out(1.7)',
          stagger,
        },
      )
    }

    // Leave animation
    return gsap.to(elements, {
      opacity: 0,
      x: -50,
      duration: 0.3,
      ease: 'power3.inOut',
      stagger,
    })
  },
  renderList(list: HTMLUListElement, elements: HTMLElement[]) {
    // Render in a fragment to avoid multiple reflows
    const fragment = document.createDocumentFragment()

    for (const element of elements) {
      fragment.append(element)
    }

    // Replace list children with the fragment
    list.replaceChildren(fragment)
  },
}))
