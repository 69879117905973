/**
 * Encrypts a string using the SHA-256 algorithm. (Browser only)
 * @param input - The string to encrypt
 * @returns The hash
 */
export async function SHA256Encrypt(input: string): Promise<string> {
  // Convert the input string to a Uint8Array
  const encoder = new TextEncoder()
  const data = encoder.encode(input)

  // Hash the data using the Web Crypto API
  const hashBuffer = await crypto.subtle.digest('SHA-256', data)

  // Convert the hash buffer to a hexadecimal string
  const hashArray = [...new Uint8Array(hashBuffer)]
  const hashHex = hashArray
    .map((byte) => byte.toString(16).padStart(2, '0'))
    .join('')

  return hashHex
}
