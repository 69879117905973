import { debounce } from 'radash'
import { WCProduct } from '~/types/woocommerce'

interface CartUpdate {
  quantity: number
  product: WCProduct
}

const cartPrefix = 'experimental__woocommerce_blocks-cart-'

const cart = {
  count: document.body.dataset.cartCount
    ? Number.parseInt(document.body.dataset.cartCount)
    : 0,
  init() {
    // WC hooks: https://github.com/woocommerce/woocommerce/issues/42058#issuecomment-1850734750

    window.wp?.hooks.addAction(
      cartPrefix + 'set-item-quantity',
      'wc',
      // Here we debounce the update to avoid multiple updates.
      // e.g.:
      // Let's say the maximum quantity of an item is 10 and the user types in 20,
      // this action will be triggered twice with the value 20 and then
      // immediately 10 because the input auto-corrects to 10.
      // -> In that case, 10 is the correct value to apply
      debounce({ delay: 1000 }, async (data: CartUpdate) => {
        const diff = data.quantity - data.product.quantity
        this.setCount(this.count + diff)
      }),
    )
    window.wp?.hooks.addAction(
      cartPrefix + 'remove-item',
      'wc',
      (data: CartUpdate) => {
        this.setCount(this.count - data.quantity)
      },
    )
    window.wp?.hooks.addAction(
      cartPrefix + 'add-item',
      'wc',
      (data: CartUpdate) => {
        this.setCount(this.count + data.quantity)
      },
    )
  },
  setCount(count: number) {
    this.count = count
  },
}

declare module 'alpinejs' {
  interface Stores {
    cart: typeof cart
  }
}

export default cart
